/* eslint-disable*/
import VueStoreService from './VueStoreService';
const SpecialityTemplateService = {
    async getSpecialitys(filter,store){
        var resp = await VueStoreService.get("template_speciality"+filter, store)
        return resp;
    },
    async saveSpeciality(data,store){
        var resp = await VueStoreService.post("template_speciality",data,store)
        return resp;
    },
    async getSpecialityById(speciality_id,store){
        var resp = await VueStoreService.get( "template_speciality/"+speciality_id,store)
        return resp;
    },
    async updateSpeciality(speciality_id,data,store){
        var resp = await VueStoreService.put("template_speciality/"+speciality_id,data,store)
        return resp;
    },

    async deleteSpeciality(speciality_id,store){
        var resp = await VueStoreService.delete("template_speciality/"+speciality_id,store)
        return resp;
    },
}

export default SpecialityTemplateService;